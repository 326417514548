import React  from "react";
import i18n from  "../i18n"
import { useTranslation } from "react-i18next";

export default function AddToHomeRequest() {
  const { i18n, t } = useTranslation();

  if(window.matchMedia('(display-mode: standalone)').matches) {
    return ''
  } else {
    return (
      <section className="wrapper style2">
        <div className="row inner">
          <div id="add-to-home-screen" className="col-6 col-12-xsmall align-center">
            { t("add_to_home_request.message") }<br/>
            <a className="button primary icon solid fa-home fit" id="js-addToHomeButton">{t("add_to_home_request.button")}</a>
          </div>
        </div>
      </section>
    );
  }
}
