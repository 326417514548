import React, { useEffect, useState }  from "react";
import i18n from  "../i18n"
import { useTranslation } from "react-i18next";

export default function AskNotificationPermission() {
  const { i18n, t } = useTranslation();
  const [AskForNotification, setAskForNotification] = useState(false);

  const urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  const saveSubscription = async (subscription, oldEndpointSubscription) => {
    const SERVER_URL = '/api/web_subscriptions.json';
    const body = JSON.stringify(subscription.toJSON());
    if(oldEndpointSubscription != null) {
      body['oldEndpointSubscription'] = oldEndpointSubscription;
    }
    const response = await fetch(SERVER_URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body
    })
    document.location.reload();
    return response.json()
  }

  const isPushManagerActive = (pushManager) => {
    if (!pushManager) {
        if (!window.navigator.standalone) {
            document.getElementById('add-to-home-screen').style.display = 'block';
        } else {
            throw new Error('PushManager is not active');
        }
        document.getElementById('subscribe_btn').style.display = 'none';
        return false;
    } else {
        return true;
    }
  }

  const askForNotification = async () => {
    let swRegistration = await navigator.serviceWorker.getRegistration();
    let pushManager = swRegistration.pushManager;
    if (!isPushManagerActive(pushManager)) {
        return;
    }
    try {
      const applicationServerKey = urlB64ToUint8Array('BBLv9qaJAYTbuQFWOjhZyjti7aSaRMSopHXeuUmiPQAd0yKl2JCl-ycZoocmgMa_FRnd1vJso_b6p6u1D2T1pEg')
      const subscriptionOptions = { applicationServerKey, userVisibleOnly: true }
      pushManager.subscribe(subscriptionOptions).then(function (subscription) {
        if(subscription.endpoint) {
          saveSubscription(subscription, null);
        }
        return subscription;
      }).catch(function (error) {
        console.log('Error:', error);
        throw error;
      });

    } catch (error) {
      console.warn("User denied push permission")
    }
  }

  useEffect(() => {
    const checkPermission = async () => {
      let swRegistration = await navigator.serviceWorker.getRegistration();
      let pushManager = swRegistration.pushManager;
      let permissionState = await pushManager.permissionState({userVisibleOnly: true})
      if(permissionState != 'granted') {
        setAskForNotification(true)
      }
    }

    checkPermission()
  })

  const showAskButton = () => {
    if (AskForNotification) {
      return (
        <div >
          <div id="ask-notification-permission">
          { t("ask_notification_permission.message") }
          <a className="button primary small fit icon solid fa-plus-square" onClick={(e) => askForNotification()}>{t("ask_notification_permission.button")}</a>
          </div>
        </div>
      )
    }

    return <></>;
  }

  return (
    showAskButton()
  );
}
