console.log("LOADED PWA")

async function initServiceWorker() {
  console.log("INIT service worker")
  let swRegistration = await navigator.serviceWorker.register('/service-worker.js')
  console.log('ServiceWorker registered: ');
  let pushManager = swRegistration.pushManager;

  if (!isPushManagerActive(pushManager)) {
      return;
  }

  let permissionState = await pushManager.permissionState({userVisibleOnly: true});
  switch (permissionState) {
      case 'prompt':
        console.log("Prompt")
          break;
      case 'granted':
        // pushManager.getSubscription().then(function (subscription) {
        //   //   console.log(subscription.toJSON())
        //   //   return subscription;
        // }).catch(function (error) {
        //     console.log('Error:', error);
        //     throw error;
        // });
        console.log("NOTIFICATION GRANTED")
          break;
      case 'denied':
        console.log("NOTIFICATION DENIED")
  }
}

function isPushManagerActive(pushManager) {
  if (!pushManager) {
      if (!window.navigator.standalone) {
          // document.getElementById('add-to-home-screen').style.display = 'block';
          console.log("NAVIGATOR STANDALONE")
      } else {
          throw new Error('PushManager is not active');
      }
      return false;
  } else {
      return true;
  }
}

// async function subscribeToPush() {
//   // Public part of VAPID key, generation of that covered in README
//   // All subscription tokens associated with that key, so if you change it - you may lose old subscribers
//   const VAPID_PUBLIC_KEY = urlB64ToUint8Array('BBLv9qaJAYTbuQFWOjhZyjti7aSaRMSopHXeuUmiPQAd0yKl2JCl-ycZoocmgMa_FRnd1vJso_b6p6u1D2T1pEg');
//
//   let swRegistration = await navigator.serviceWorker.getRegistration();
//   let pushManager = swRegistration.pushManager;
//   if (!isPushManagerActive(pushManager)) {
//       return;
//   }
//   let subscriptionOptions = {
//       userVisibleOnly: true,
//       applicationServerKey: VAPID_PUBLIC_KEY
//   };
//   try {
//       let subscription = await pushManager.subscribe(subscriptionOptions);
//       displaySubscriptionInfo(subscription);
//       // Here you can send fetch request with subscription data to your backend API for next push sends from there
//   } catch (error) {
//       console.log('User denied push permission');
//   }
// }

// function displaySubscriptionInfo(subscription) {
//   console.warn(subscription)
// }

if ((new URLSearchParams(window.location.search)).get('page') === 'success') {
  // document.getElementById('content').innerHTML = 'You successfully opened page from WebPush! (this url was that was set in json data param)';
}

if (navigator.serviceWorker) {
  initServiceWorker();
} else {
  console.error("serviceWorker on navigator is undefined")
}
