var Haikunator = require('haikunator')

var problemTitle = document.getElementById('boulder_title')
if(problemTitle) {
  // Instantiate Haikunator with default options
  m_adjective = [
    'autunnale', 'nascosto', 'amaro', 'annebbiato', 'silenzioso', 'vuoto', 'secco', 'oscuro', 'estivo',
    'ghiacciato', 'delicato', 'tranquillo', 'bianco', 'figo', 'primaverile', 'invernale', 'paziente',
    'crepuscolare', 'lucente', 'criminale', 'fine', 'temperato', 'azzurro', 'areggiato',
    'rotto', 'freddo', 'umido', 'caduto', 'gelido', 'verde', 'lungo', 'ritardatario', 'persistente',
    'grosso', 'piccolo', 'mattiniero', 'fangoso', 'vecchio', 'rosso', 'rude', 'ancora',
    'scintillante', 'frizzante', 'timido', 'vagante', 'appassito', 'selvaggio', 'nero',
    'giovane', 'santo', 'solitario', 'fragrante', 'datato', 'nevoso', 'orgoglioso', 'floreale',
    'irrequieto', 'divino', 'lucido', 'antico', 'viola', 'vivace', 'senza nome'
  ];

  f_adjective = [
    'autunnale', 'nascosta', 'amara', 'annebbiata', 'silenziosa', 'vuota', 'secca', 'oscura', 'estiva',
    'ghiacciata', 'delicata', 'tranquilla', 'bianca', 'figa', 'primaverile', 'invernale', 'paziente',
    'crepuscolare', 'lucente', 'criminale', 'fine', 'temperata', 'azzurra', 'areggiata',
    'rotta', 'fredda', 'umida', 'caduta', 'gelida', 'verde', 'lunga', 'ritardataria', 'persistente',
    'grossa', 'piccola', 'mattiniera', 'fangosa', 'vecchia', 'rossa', 'rude', 'ancora',
    'scintillante', 'frizzante', 'timida', 'vagante', 'appassita', 'selvaggia', 'nera',
    'giovane', 'santa', 'solitaria', 'fragrante', 'datata', 'nevosa', 'orgogliosa', 'floreale',
    'irrequieta', 'divina', 'lucida', 'antica', 'viola', 'vivace', 'senza nome'
  ];

  m_nouns = [
    'fiume', 'vento', 'mare', 'mattino', 'lago', 'temporale', 'sole', 'uccello', 'fuoco',
    'fiore', 'albero', 'fiocco di neve', 'silenzio', 'suono', 'cielo', 'surf', 'fulmine',
    'lampo', 'tuono', 'diario', 'sogno', 'rospo', 'foglio', 'fumo', 'paradiso', 'tramonto',
    'picco', 'campo'
  ];

  f_nouns = [
    'cascata', 'brezza', 'luna', 'pioggia', 'mattina', 'neve', 'alba', 'cima', 'foglia',
    'ombra', 'nuvola', 'collina', 'farfalla', 'spazzola', 'polvere', 'erba', 'montagna',
    'notte', 'oscurità', 'forma', 'viola', 'acqua', 'onda', 'ciliegia', 'pianta',
    'voce', 'stella'
  ];

  male_or_female = Math.random() > 0.5;
  adjectives = (male_or_female) ? m_adjective : f_adjective
  nouns = (male_or_female) ? m_nouns : f_nouns

  var haikunator = new Haikunator({
    nouns: adjectives,
    adjectives: nouns,
    seed: Math.random()
  })

  generated_name = haikunator.haikunate({tokenLength: 0, delimiter: " "})

  problemTitle.value = generated_name.charAt(0).toUpperCase() + generated_name.slice(1);
}
