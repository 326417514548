import React, { useEffect } from "react";
import i18n from  "../i18n"
import { useTranslation } from "react-i18next";
import AddToHomeRequest from  "./AddToHomeRequest"
import AskNotificationPermission from  "./AskNotificationPermission"

export default function NotificationRequest() {
  const { i18n, t } = useTranslation();

  const PwaInstallationRequired = () => {
    if(window.matchMedia('(display-mode: standalone)').matches) {
      return <AskNotificationPermission></AskNotificationPermission>
    } else {
      return <AddToHomeRequest></AddToHomeRequest>
    }
  }

  return (
    <div className="pwa-notification">
      { PwaInstallationRequired() }
    </div>
  );
}
