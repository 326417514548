import Swal from 'sweetalert2/dist/sweetalert2.js'

$(function() {
  const badges = document.querySelectorAll('div.badge-alert');

  badges.forEach(badge => {
    Swal.fire({
      title: badge.dataset.title,
      text: badge.dataset.message,
      imageUrl: badge.dataset.image,
      imageWidth: 200,
      imageHeight: 200,
      imageAlt: badge.dataset.name
    })
  })
})

