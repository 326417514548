import { useState } from 'react';
import React from "react";
import i18n from  "../i18n"
import { useTranslation } from "react-i18next";
import moment from 'moment';

export default function AvailableEvents({Events}) {
  const { i18n, t } = useTranslation();

  const AvailableReservation = (Event) => {
    if(Event.reservations_count) {
      return t("reservations.remaining_seat", {available_reservations: Event.max_reservations - Event.reservations_count})
    } else {
      return ''
    }
  }

  const EventAction = (Event) => {
    if(Event.is_booked) {
      return t("reservations.is_booked")
    } else {
      return <a className="button primary small fit icon solid fa-plus-square" data-remote="true" style={{padding: '0 10px'}} href={Event.create_event_url}>{t("reservations.book")}</a>
    }
  }

  return (
    <div className="events">
      <h3>Eventi del giorno:</h3>
      { Events.map((event) => {
        return (
          <div className="row event" key={event.id}>
            <div className=" event-info col-8 align-left">
              {event.title} <em>{moment(event.start_at).format("HH:mm")}</em> -
              <em>{moment(event.end_at).format("HH:mm")}</em> {AvailableReservation(event)}
            </div>
            <div className="event-action col-4 align-center">
              {EventAction(event)}
            </div>
          </div>
        )
      })}
    </div>
  );
}
