import "core-js/stable";
import "regenerator-runtime/runtime"
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@rails/ujs").start()
require("@rails/activestorage").start()

$('input[name="boulder[wall_id]"], input[name="route[wall_id]"]').click(function(){
  const element = document.querySelector('.image-editor');
  const rect = element.getBoundingClientRect()
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

  window.scroll({
    top: rect.top + rect.height / 2 - viewHeight / 2,
    behavior: 'smooth'
  });
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')
window.$ = window.jQuery = require('jquery');
window.Swal = require('sweetalert2/dist/sweetalert2.js');

import main from '../src/js/main.js'
import '../site/badge-alert.js'
import '../site/qrcode.js'
import '../site/image-editor.js'

import '../site/jungleboard-image-editor.js'
import '../site/modal.js'
import '../site/random-problem-name.js'
import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/css/all'
import '../src/sass/main.scss'
import './pwa.js'
import 'autotrack'

FontAwesome.config.autoReplaceSvg = "nest"
import Turbolinks from "turbolinks"
Turbolinks.start()

import CookiesEuBanner from "cookies-eu-banner/dist/cookies-eu-banner"
new CookiesEuBanner(function () {
  if(process.env.NODE_ENV == 'production') {
    ga('create', 'UA-156436151-1', 'auto');

    // Only require the plugins you've imported above.
    ga('require', 'eventTracker');
    ga('require', 'outboundLinkTracker');
    ga('require', 'urlChangeTracker');
    ga('require', 'maxScrollTracker');

    ga('send', 'pageview');

    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
  }
});

document.addEventListener('DOMContentLoaded', function () {
  window.AddToHomeScreenInstance = new window.AddToHomeScreen({
    appName: 'JungleRock',                                   // Name of the app.
                                                           // Required.
    appIconUrl: 'apple-touch-icon.png',                    // App icon link (square, at least 40 x 40 pixels).
                                                           // Required.
    assetUrl: 'https://cdn.jsdelivr.net/gh/philfung/add-to-homescreen@2.2/dist/assets/img/',  // Link to directory of library image assets.

    maxModalDisplayCount: -1                               // If set, the modal will only show this many times.
                                                           // Optional. Default: -1 (no limit).  (Debugging: Use this.clearModalDisplayCount() to reset the count)
  });
});

$(document).on('click', '#js-addToHomeButton', function () {
  window.AddToHomeScreenInstance.show('it');
});

import 'sweetalert2/dist/sweetalert2.css'
import "lightbox2/dist/css/lightbox"
import lightbox from "lightbox2/dist/js/lightbox"
lightbox.option({
  'resizeDuration': 200,
  'wrapAround': true,
  'alwaysShowNavOnTouchDevices': true
})

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
