const ImageEditor = require('tui-image-editor');

$(function() {
  var $form = $('#js-problemForm form[data-remote=true]')
  if($form.find("#tui-image-editor").length == 0) {
    return false;
  }

  document.body.addEventListener("ajax:before", function(event, b) {
    let fileInputElement = document.getElementById('boulder_image');
    let data = dataURLToBlob(imageEditor.toDataURL());

    let file = new File([data], "image.png",{type: data.type, lastModified:new Date().getTime()});
    let container = new DataTransfer();
    container.items.add(file);
    fileInputElement.files = container.files;
  });

  var dataURLToBlob = function(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = parts[1];

        return new Blob([raw], {type: contentType});
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
}

  var $boulderImage = $form.find("input[type=file]");

  var imageSelected = false;
  var hold_color_present = false;
  var wall_selected = false;
  var socialBoulder = $('.social-boulder').length;
  var colorCircle = false ;

  var $inputImage = $('#boulder_image');
  var supportingFileAPI = !!(window.File && window.FileList && window.FileReader);
  var $btnUndo = $('#btn-undo');
  var $btnRedo = $('#btn-redo');
  var $btnRemoveShape = $('#btn-remove-active-object');
  var $btnChangeColorRed = $('#btn-red-circle');
  var $btnChangeColorBlue = $('#btn-blue-circle');
  var $btnChangeColorGreen = $('#btn-green-circle');
  var shapeOptions = {};
  shapeOptions.stroke = '#000000';
  shapeOptions.fill = 'transparent';
  var shapeType = 'circle';

  function resizeEditor() {
    var $editor = $('#tui-image-editor');
    var $container = $('.tui-image-editor-canvas-container');
    var height = parseFloat($container.css('max-height'));

    $editor.height(height);
  }

  function activateShapeMode() {
    if (imageEditor.getDrawingMode() !== 'SHAPE') {
      imageEditor.stopDrawingMode();
      imageEditor.startDrawingMode('SHAPE');
    }
  }

  function prepareCircle(color) {
    colorCircle = color;
  }

  function changeColor(color) {
    imageEditor.discardSelection();
    shapeOptions.stroke = color;
    shapeOptions.strokeWidth = 5;
    prepareCircle(color);
  }

  $btnRemoveShape.on('click', function() {
    imageEditor.removeObject(activeObjectId);
  });

  $btnChangeColorRed.on('click', function(){
    changeColor('#ff0000')
  });

  $btnChangeColorBlue.on('click', function(){
    changeColor('#0000ff')
  });

  $btnChangeColorGreen.on('click', function(){
    changeColor('#00ff00')
  });

  var imageEditor = new ImageEditor(document.querySelector('#tui-image-editor'), {
    cssMaxWidth: 700,
    cssMaxHeight: 500,
    selectionStyle: {
      cornerSize: 50,
      rotatingPointOffset: 100,
    },
  });
  imageEditor.changeSelectableAll(false);

  $btnUndo.on('click', function () {
    if (!$(this).hasClass('disabled')) {
      imageEditor.discardSelection();
      imageEditor.undo();
    }
  });

  $btnRedo.on('click', function () {
    if (!$(this).hasClass('disabled')) {
      imageEditor.discardSelection();
      imageEditor.redo();
    }
  });

  imageEditor.on({
    objectAdded: function (objectProps) {
      console.info(objectProps);
    },
    undoStackChanged: function (length) {
      if (length) {
        $btnUndo.removeClass('disabled');
      } else {
        $btnUndo.addClass('disabled');
      }
      resizeEditor();
    },
    redoStackChanged: function (length) {
      if (length) {
        $btnRedo.removeClass('disabled');
      } else {
        $btnRedo.addClass('disabled');
      }
      resizeEditor();
    },
    objectActivated: function (obj) {
      activeObjectId = obj.id;
      if (obj.type === 'rect' || obj.type === 'circle' || obj.type === 'triangle') {
        // activateShapeMode();
      }
    },
    mousedown: function (event, originPointer) {
      if (colorCircle != false ) {
        imageEditor.addShape("circle", {
          fill: 'transparent',
          stroke: colorCircle,
          strokeWidth: 25,
          left: originPointer.x,
          top: originPointer.y,
          rx: 100,
          ry: 100,
        });
        colorCircle = false;
      }
    }
  });

  $('input[name="boulder[wall_id]"]').on('change', function(event) {
    var wall = event.currentTarget;
    var $wall = $(wall);
    if($wall.prop('checked')) {
      if($wall.data('static')) {
        $('.image-editor').hide();
        // var imageUrl = `${$(wall).data('image-url')}?r=${Math.random()}`;
        var imageUrl = $(wall).data('image-url')
        imageEditor.loadImageFromURL(imageUrl, $(wall).data('name')).then(function (result) {
          imageEditor.clearUndoStack();
          changeColor('#ff0000');
          imageSelected = true;
          checkSubmit();
          $("#js-ImageEditorMenu").addClass('image-selected');
        });
      } else {
        $('.image-editor').show();
        $("#js-ImageEditorMenu").removeClass('image-selected');
      }
    }
  });

  $inputImage.on('change', function (event) {
    var file;

    if (!supportingFileAPI) {
      alert('This browser does not support file-api');
    }

    file = event.target.files[0];
    imageEditor.loadImageFromFile(file).then(function (result) {
      imageEditor.clearUndoStack();
      changeColor('#ff0000');
      imageSelected = true;
      checkSubmit();
      $("#js-ImageEditorMenu").addClass('image-selected');
    });
  });

  $("#boulder_holds_color").on("keyup", function(e){
    hold_color_present = $(e.currentTarget).val().length > 0
    checkSubmit();
  })

  $("input[name=\"boulder[wall_id]\"]").on("change", function(){
    wall_selected = $("input[name=\"boulder[wall_id]\"]:checked").length > 0
    checkSubmit();
  })

  function checkSubmit() {
    if(wall_selected && imageSelected && (socialBoulder || hold_color_present)) {
      $form.find("input[type=submit]").removeAttr('disabled');
    } else {
      $form.find("input[type=submit]").attr('disabled', true);

    }
  }
})
