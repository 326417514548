import { useState } from 'react';
import React from "react";
import i18n from  "./i18n";
import { useTranslation } from "react-i18next";
import ProblemCard from "./ProblemCard";

export default function ProblemFilter({ Problems }) {
  const { i18n, t } = useTranslation();
  const [item, setItem] = useState(Problems);
  const difficultyOptions = [...new Set(Problems.map((Val) => Val.difficulty))];
  const [filters, setFilter] = useState({difficulty: '', sent: 0});

  const filterItem = (key, value) => {
    filters[key] = value
    setFilter(filters);

    $(`.Hotspot`).map((i, hotspot) => $(hotspot).hide())
    if(filters.difficulty != "") {
      $(`.Hotspot`).map((i, hotspot) => $(hotspot).hide())
      $(`.Hotspot.${filters.difficulty}`).map((i, hotspot) => $(hotspot).show());
    } else {
      $(`.Hotspot`).map((i, hotspot) => $(hotspot).show())
    }

    const newItem = Problems.filter((newVal) => {
      if(!(filters.difficulty === "" || newVal.difficulty === filters.difficulty)) {
        return false;
      }

      if(filters.sent === true) {
        return newVal.sent === false
      }

      return true;
    });
    setItem(newItem);
  };

  return (
    <>
      <div className="row">
        <div className="col-6 col-6-xsmall align-left">
          <select id="difficulty" onChange={(e) => filterItem('difficulty', e.target.value)}>
            <option value="">{t("difficulty.all")}</option>
            {difficultyOptions.map((Difficulty) => {
              return <option value={Difficulty} key={Difficulty}>{t(`difficulty.${Difficulty}`)}</option>
            })}
          </select>
        </div>

        <div className="col-6 col-6-xsmall">
          <input type="checkbox" id="sent" value="1" onChange={ e => filterItem('sent', e.target.checked)}></input>
          <label htmlFor="sent">{t("show_only_not_sent")}</label>
        </div>
      </div>
      <table data-turbolinks='false'>
        <tbody>
          {item.map((Problem) => {
            return <ProblemCard Problem={Problem} key={Problem.id}></ProblemCard>
          })}
        </tbody>
      </table>
    </>
  );
}
