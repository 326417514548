import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "it",
  fallbackLng: "it",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    it: {
      translation: {
        difficulty: {
          all: 'Tutte',
          white: 'Bianco',
          green: 'Verde',
          yellow: 'Giallo',
          red: 'Rosso',
          black: 'Nero',
        },
        holds_color: "Prese: {{holds_color}}",
        show_only_not_sent: "Non liberati",
        sent_count_zero: "Non liberato",
        sent_count_one: "Una libera",
        sent_count_other: "{{count}} libere",
        setter: "by",
        reservations: {
          remaining_seat: "({{available_reservations}} posti disponibili)",
          is_booked: "Prenotato",
          book: "Prenota",
          switch: "Scambia",
          remove: "Cancella",
          confirmation_remove: "Sicuro di voler cancellare la tua prenotazione?",
          whole_day: "Giorno intero",
          only_working_hours: "Solo orari lavorativi",
          booked_count_zero: "0 Prenotazioni",
          booked_count_one: "1 Prenotazione",
          booked_count_other: "{{count}} Prenotazioni",
          booked_with_event: "({{uncompleted_event}}/{{opened_event}} Per eventi)"
        },
        add_to_home_request: {
          message: "L'applicazione di JungleRock per restare sempre aggiornato con noi",
          button: "Installa la nostra applicazione"
        },
        ask_notification_permission: {
          denied: "Non hai autorizzato le notifiche su questo dispositivo",
          message: "Attiva le notifiche per restare sempre aggiornato con noi. Promettiamo che non saremo noiosi",
          button: "Attiva le notifiche"
        }
      }
    },
    en: {
      translation: {
        difficulty: {
          all: 'All',
          white: 'White',
          green: 'Green',
          yellow: 'Yellow',
          red: 'Red',
          black: 'Black',
        },
        holds_color: "Holds: {{holds_color}}",
        show_only_not_sent: "Not sent",
        sent_count_zero: "Not sent",
        sent_count_one: "only one sent",
        sent_count_other: "{{count}} sent",
        setter: "Setter",
        reservations: {
          remaining_seat: "({{available_reservations}} available seat)",
          is_booked: "Already booked",
          book: "Book",
          switch: "Switch",
          remove: "Remove",
          confirmation_remove: "Are you sure to remove this Reservation?",
          whole_day: "Giorno intero",
          only_working_hours: "Solo orari lavorativi",
          booked_count_zero: "0 Reservations",
          booked_count_one: "1 Reservation",
          booked_count_other: "{{count}} Reservations",
          booked_with_event: "({{uncompleted_event}}/{{opened_event}} for events)"
        },
        add_to_home_request: {
          message: "Add our app to home screen",
          button: "Add to home screen"
        },
        ask_notification_permission: {
          denied: "Notification are not allowed on this device",
          message: "Allow our notification",
          button: "Allow notification"
        }
      }
    }
  },
});

export default i18n;
