import React from "react";
import PropTypes from "prop-types";
import ProblemFilter from './ProblemFilter.js';
import "./i18n"

class ProblemIndex extends React.Component {
  render () {
    return (
      <ProblemFilter Problems={this.props.problems}></ProblemFilter>
    );
  }
}

ProblemIndex.propTypes = {
  problems: PropTypes.array
};
export default ProblemIndex
