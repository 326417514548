import React from "react";
import "./i18n"
import moment from 'moment';

import { useState } from 'react';
import Calendar from 'react-calendar';
import DayAvailabilities from "./reservations/DayAvailabilities.js";
import { faUnderline } from "@fortawesome/free-solid-svg-icons";

function ReservationAvailabilities({ Days, IsAdmin, OffsetDays, SelectedDate}) {
  const available_days = Days.reduce((availabilities, day) => {
    availabilities[day.day] = day.events.length > 0 || day.slots.length > 0

    return availabilities;
  }, {})
  let start_day_index;
  if(OffsetDays != undefined) {
    const offsetDay = moment().add(OffsetDays, 'days').format('YYYY-MM-DD')
    if(available_days[offsetDay]) {
      start_day_index = Days.findIndex((day) => day.day === offsetDay);
    } else {
      start_day_index = Days.findIndex((day) => available_days[day.day]);
    }
  } else {
    if(SelectedDate != undefined) {
      start_day_index = Days.findIndex((day) => day.day === SelectedDate);
    } else {
      start_day_index = Days.findIndex((day) => available_days[day.day]);
    }
  }

  const [value, onChange] = useState(moment(Days[start_day_index].day, 'YYYY-MM-DD'));
  const selectedDay = Days.find((day) => day.day === moment(value).format('YYYY-MM-DD'))

  const tileDisabled = (date) => {
    return !available_days[moment(date).format('YYYY-MM-DD')]
  }

  return (
    <>
      <Calendar locale="it-IT" onChange={onChange} value={value} maxDate={moment().add(15, 'days').toDate()} minDate={new Date()} tileDisabled={({date}) => tileDisabled(date)}/>
      <DayAvailabilities SelectedDay={selectedDay} IsAdmin={IsAdmin}></DayAvailabilities>
    </>
  );
}

ReservationAvailabilities.propTypes = {
};
export default ReservationAvailabilities
