var context;
var canvas;
$(function() {
  canvas = document.getElementById('js-jungleboard');
  if(canvas) {
    context = canvas.getContext('2d');
    var img = document.getElementById('js-boardImg');

    context.drawImage(img, 0, 0);

    var xInit = 42;
    var yInit = 22;
    var step = 23

    var moves = $('#moves').data('moves');
    $.each(moves, function( _i, value ) {
      console.log(value['description']);
      console.log(value['isStart']);
      console.log(value['isEnd']);
      let letter = value['description'].slice(0, 1);
      let number = value['description'].slice(1);

      xAxis = xInit + step * (letter.charCodeAt(0) - 65);
      yAxis = 455 - (yInit + step * (Number(number) - 1));

      let color;
      if(value['isStart'] == true) {
        color = 'green';
      } else {
        if(value['isEnd'] == true) {
          color = 'red';
        } else {
          color = 'blue';
        }
      }

      arc(xAxis, yAxis, 10, color)
    })

  }
})

function arc(x, y, r, color) {
  context.beginPath();
  context.arc(x, y, r, 0, 2 * Math.PI);
  context.lineWidth = 2;
  context.strokeStyle = color;
  context.stroke();
}
