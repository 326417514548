import { useState } from 'react';
import React from "react";
import i18n from  "./i18n"
import { useTranslation } from "react-i18next";

export default function ProblemCard({Problem}) {
  const { i18n, t } = useTranslation();

  const alreadySent = (sent) => {
    if(sent) {
      return (
        <span className="icon fas fa-check-circle"></span>
      )
    }
  }

  const beautyStars = (beauty) => {
    return [...Array(5)].map((e, i) => {
      if(i < beauty) {
        if((i + 0.5) === beauty) {
          return <span className="icon fas fa-star-half-alt" key={i}></span>
        } else {
          return <span className="icon fas fa-star" key={i}></span>
        }
      } else {
        return <span className="icon far fa-star" key={i}></span>
      }
    })
  }

  return (
    <>
      <tr>
        <td style={{ borderLeft: `${Problem.difficulty} 4px solid`, width: '80px' }} className='cover'>
          <a data-lightbox='image' data-title={Problem.title} href={Problem.cover_image}>
            <img src={Problem.cover_url}/>
          </a>
          <span style={{fontSize: '12px', marginLeft: '3px'}}>
            {t("sent_count", { count: Problem.sent_count })}
          </span><br/>
        </td>
        <td>
          { alreadySent(Problem.sent) }
          { ` ${Problem.title}` }
          <br/>
          ({ Problem.beauty_avg.toFixed(1) })
          { beautyStars(Problem.beauty_avg) }
          <br/>
          <span style={{fontSize: '12px'}}>
            {t("setter")}: {Problem.setter }
          </span><br/>
          <span style={{fontSize: '12px'}}>
            {t("holds_color", {holds_color: Problem.holds_color})}
          </span>
        </td>
        <td className="align-right actions">
          <sup>{Problem.slot_name}</sup><br/>
          <a className="button small" style={{padding: '0 10px'}} href={Problem.problem_path}>Vai</a>
        </td>
      </tr>
    </>
  );
}
