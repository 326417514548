import Swal from 'sweetalert2/dist/sweetalert2.js'

$(function() {
  const badges = document.querySelectorAll('div.modal');

  badges.forEach(badge => {
    Swal.fire({
      title: badge.dataset.title,
      text: badge.dataset.message,
      icon: badge.dataset.icon
    })
  })

  const users_list = document.querySelectorAll('.users-list .modal-trigger')
  users_list.forEach(link => {
    link.onclick = function(e) {
      let user_links = jQuery(e.currentTarget).data('usernameList').map(user =>
        `${user.img} ${user.link}`
      )

      Swal.fire({
        title: 'Boulder liberato da:',
        html: `<ul class="user-list"><li>${user_links.join("</li><li>")}</li></ul>`
      })
      e.preventDefault();
    }
  })
})
