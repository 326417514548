import React from "react"
import PropTypes from "prop-types"
class PersonalClimbingStat extends React.Component {
  render () {
    return (
      <React.Fragment>
        User: {this.props.userId}
      </React.Fragment>
    );
  }
}

PersonalClimbingStat.propTypes = {
  userId: PropTypes.node
};
export default PersonalClimbingStat
