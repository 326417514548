import { useState } from 'react';
import React from "react";

import "../i18n"
import { useTranslation } from "react-i18next";

import AvailableEvents from  "./AvailableEvents"
import AvailableSlots from  "./AvailableSlots"

export default function DayAvailabilities({SelectedDay, IsAdmin}) {
  const { i18n, t } = useTranslation();
  const [showWholeDay, setShowWholeDay] = useState(false);

  const EventsOfTheDay = function () {
    if(SelectedDay.events.length > 0) {
      return <AvailableEvents Events={SelectedDay.events}></AvailableEvents>
    } else {
      return '';
    }
  }

  const showWorkingHoursLabel = () => {
    if(showWholeDay) {
      return t("reservations.only_working_hours")
    } else {
      return t("reservations.whole_day")
    }
  }

  const ToggleDayHoursCheckbox = () => {
    if(IsAdmin) {
      return (
        <>
          <input type="checkbox" id="toggleNotWorkingHours" onChange={ () => setShowWholeDay(!showWholeDay)} />
          <label htmlFor="toggleNotWorkingHours">{showWorkingHoursLabel()}</label>
        </>
      )
    }

    return ''
  }

  return (
    <>
      {ToggleDayHoursCheckbox()}
      {EventsOfTheDay()}
      <AvailableSlots Slots={SelectedDay.slots} ShowWholeDay={showWholeDay} ReservationOfDay={SelectedDay.reservation_of_day} IsAdmin={IsAdmin}></AvailableSlots>
    </>
  );
}
