import { useState } from 'react';
import React from "react";
import i18n from  "../i18n"
import { useTranslation } from "react-i18next";
import moment from 'moment';

export default function AvailableSlots({Slots, ShowWholeDay, ReservationOfDay, IsAdmin}) {
  const { i18n, t } = useTranslation();

  const SlotAttendancePercentage = (percentage) => {
    if(percentage > 80) {
      return <i className="fas fa-users" style={{color: 'red'}}></i>
    } else {
      if(percentage > 50) {
        return <i className="fas fa-user-friends" style={{ color: 'orange'}}></i>
      } else {
        return <i className="fas fa-user" style={{ color: 'green'}}></i>
      }
    }
  }

  const PreviewSwapReservationPath = (slot) => {
    return `${ReservationOfDay.preview_swap_reservation_path}?date=${moment(slot.date).unix()}&slot=${slot.slot}`
  }

  const PreviewSwapReservationButton = (slot) => {
    const buttonClass = "button small primary icon solid fa-reply-square"

    return (
      <a style={{float: 'right'}} className={buttonClass} href={PreviewSwapReservationPath(slot)} data-remote="true">
        {t('reservations.switch')}
      </a>
    )
  }

  const PreviewReservationButton = (slot) => {
    const buttonClass = "button small primary icon solid fa-plus-square"

    return (
      <a style={{float: 'right'}} className={buttonClass} href={slot.reservation_path} data-remote="true">
        {t('reservations.book')}
      </a>
    )
  }

  const RemoveReservationButton = (removePath) => {
    const buttonClass = "button small primary icon solid fa-minus-square"
    const confirmText = t("reservations.confirmation_remove")

    return (
      <a style={{float: 'right'}} className={buttonClass} data-confirm={confirmText} rel="nofollow" data-method="delete" href={removePath}>
        {t("reservations.remove")}
      </a>
    )
  }

  const LinkReservation = (slot) => {
    if(IsAdmin || ReservationOfDay === undefined) {
      return PreviewReservationButton(slot)
    } else {

      if(ReservationOfDay.start_at == slot.start_time) {
        return RemoveReservationButton(ReservationOfDay.remove_path)
      } else {
        if(ReservationOfDay.start_at > new Date().toISOString()) {
          return PreviewSwapReservationButton(slot)
        } else {
          if(ReservationOfDay.checkin_at) {
            return 'Entrato'
          } else {
            return ''
          }
        }
      }
    }
  }

  const ReservationCountDetails = (Slot) => {
    if(IsAdmin) {
      if(Slot.reservation_for_opened_event > 0) {
        return (
          <>
            <br />
            <sub>
              {t(
                "reservations.booked_with_event",
                {
                  uncompleted_event: Slot.reservations_for_uncompleted_event,
                  opened_event: Slot.reservation_for_opened_event
                }
              )}
            </sub>
          </>
        )
      } else {
        return (
          <>
            <br />
            <sub>{t("reservations.booked_count", {count: Slot.reservation_count})}</sub>
          </>
        )
      }
    } else {
      return ''
    }
  }

  return (
    <div className="row slots-available">
      {Slots.map((slot) => {
        if(slot.is_working_time || ShowWholeDay) {
          return (
            <div className={`time_slot col-6-small col-4-medium col-12-xsmall col-3 ${ slot.already_booked ? 'booked' : ''} ${ slot.is_working_time ? '' : 'out_of_work_time'}`} key={slot.start_time} >
              {SlotAttendancePercentage(slot.percentage)} &nbsp;
              {moment(slot.start_time).format('HH:mm')}
              {LinkReservation(slot)}
              {ReservationCountDetails(slot)}
            </div>
          )
        }
      })}
    </div>
  );
}
